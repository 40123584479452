import { Component, OnInit, NgModule, AfterViewInit, ViewChild } from '@angular/core';
import { CategoriasService } from '../services/categorias.service';
import { from, of, forkJoin } from 'rxjs';
import { indexOf } from 'lodash';
import { CarouselModule, CarouselComponent } from 'ngx-bootstrap/carousel';
import { ActivatedRoute, Router } from '@angular/router';
import { HostListener } from '@angular/core';
import { Directive, Host, Self, Optional, Input, Renderer2, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../services/login.service';
import { CommonService } from '../services/common.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
})

export class InicioComponent implements OnInit, AfterViewInit {
  @ViewChild('ofertas') public ofertas: ElementRef;
  public carrouselSlideTotal:any = [];
  public itemsPerSlide = 3;
  public singleSlideOffset = true;
  public noWrap = true;
  public innerWidth: any;

  @Input() swipeThreshold = 50;
  private start: number;
  private stillMoving: boolean;
  private moveListener: Function;
  private successLog = "Iniciaste sesión correctamente";

  
  public categorias = [];
  public previousUrl;

  constructor(
    @Host() @Self() @Optional() private carousel: CarouselComponent,
    private renderer: Renderer2,
    private element: ElementRef,
    private categoriasService : CategoriasService,
    private router : Router,
    private activeRoutes: ActivatedRoute,
    private ToastrService: ToastrService,
    private LoginService: LoginService,
    private commonService: CommonService,
    private spinner: NgxSpinnerService
    ){
    }
  
    ngOnInit() {
      this.onGetAllSliders();

      if ('ontouchstart' in document.documentElement) {
        this.renderer.listen(this.element.nativeElement, 'touchstart', this.onTouchStart.bind(this));
        this.renderer.listen(this.element.nativeElement, 'touchend', this.onTouchEnd.bind(this));
      }
      this.innerWidth = window.innerWidth;
      this.itemSlideResponsive( this.innerWidth );
    
    let httpCalls = forkJoin( //permite hacer varias llamadas de una
      this.categoriasService.getAll()
    );
   
    httpCalls.subscribe( //para cualquier respuesta 
      (data: any) => {
        this.categorias = data[0].data; //cada posición de data[i] coincide con las posiciones del array que mandamos con forkjoin
      },
      (error) => { //en caso de error http
      }
    );
  
  }

  ngAfterViewInit() {
      const previusUrl = this.activeRoutes.snapshot.paramMap.get('url');
      if(previusUrl == 'ofertas') {
        //      this.render2.selectRootElement()
        if (this.ofertas !== null) {
          this.ofertas.nativeElement.scrollIntoView();
          this.ofertas = null;
        }
      }
  }



  switch(expr){
    switch (expr) {
      case 'BEBIDAS':
        return '../assets/cat-rubro/bebidas.png';
        break;
      case 'COMESTIBLES':
        return '../assets/cat-rubro/comestibles.png';
        break;
      case 'GOLOSINAS':
        return '../assets/cat-rubro/golosinas.png';
        break;
      case 'LIMPIEZA E INSECTICIDA':
        return '../assets/cat-rubro/limpieza.png';
        break;
      case 'PERFUMERIA E HIG. PERS.':
        return '../assets/cat-rubro/perfumeria.png';
        break;
      case 'PRODUCTOS DE FIESTAS':
        return '../assets/cat-rubro/productos-fiesta.png';
        break;
      case 'MASCOTAS':
        return '../assets/cat-rubro/mascotas.png';
        break;
      default:
        return '../assets/cat-rubro/autoservicio.png';
      break;
    }
  }

  
  goCat(seo, id){
    this.router.navigateByUrl('/categorias/' + seo + '/' + id);
  }
  itemSlideResponsive(width) {
      if (width > 768) {
        // window width is at least 768px
        this.itemsPerSlide = 7;
      } else {    
        this.itemsPerSlide = 3;    
      }
  }
  private onTouchStart(e: TouchEvent): void {
    if (e.touches.length === 1) {
      this.start = e.touches[0].pageX;
      this.stillMoving = true;
      this.moveListener = this.renderer.listen(this.element.nativeElement, 'touchmove', this.onTouchMove.bind(this));
    }
  }

  private onTouchMove(e: TouchEvent): void {
    if (this.stillMoving) {
      const x = e.touches[0].pageX;
      const difference = this.start - x;
      if (Math.abs(difference) >= this.swipeThreshold) {
        this.cancelTouch();
        if (difference > 0) {
          if (this.carousel.activeSlide < this.carousel.slides.length - 1) {
            this.carousel.activeSlide = this.carousel.activeSlide + 1;
          }
        } else {
          if (this.carousel.activeSlide > 0) {
            this.carousel.activeSlide = this.carousel.activeSlide - 1;
          }
        }
      }
    }
  }
  private onTouchEnd(e: TouchEvent): void {
    this.cancelTouch();
  }

  private cancelTouch() {
    if (this.moveListener) {
      this.moveListener();
      this.moveListener = undefined;
    }
    this.start = null;
    this.stillMoving = false;
  }

  private toastLogged(){
    if ( this.LoginService.estaAutenticado() === true ) {
      this.ToastrService.success(this.successLog);
    }
    else {
      this.ToastrService.error(this.successLog);

    }
  }

  onGetAllSliders() {
    this.spinner.show();
    var mainCarousel = ((document.getElementsByClassName('carousel-inner') as HTMLCollectionOf<HTMLElement>));
   // console.log(mainCarousel);
    mainCarousel[1].style.setProperty('width', '100%', 'important');
    this.commonService.getAllSliders().subscribe(res => {
      this.carrouselSlideTotal = Array.from(Array(res['data']).keys());
      this.spinner.hide();
    });
  }

  /*
  onGetAllSliders() {
    console.log('ongetallsliders')
    this.loading = true;

    this.commonService.getAllSliders().subscribe(res => {
      console.log(res);
      this.commonService.subject_slider.next(res);
      this.loading = false;
     // this.carrouselSlideTotal = Array.from(Array(res['data']).keys());
    });
  }
  */


}
